import { createApp, h, provide } from "vue";
import App from "./App.vue";
import { config } from "@/shared/config";
import {
  ApolloClient,
  DefaultOptions,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { DefaultApolloClient } from "@vue/apollo-composable";
import router from "@/router";
import VuelidatePlugin from "@vuelidate/core";

const app = createApp({
  setup() {
    const httpLink = new HttpLink({
      uri: config.API_URL,
    });
    const defaultOptions: DefaultOptions = {
      watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore",
      },
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      },
    };
    const apolloClient = new ApolloClient({
      link: httpLink,
      cache: new InMemoryCache(),
      defaultOptions: defaultOptions,
    });
    provide(DefaultApolloClient, apolloClient);
  },
  render: () => h(App),
});

app.use(router).use(VuelidatePlugin).mount("#app");
