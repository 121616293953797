<template>
  <div class="pageSummary wrapper-medium wrapper-space">
    <div v-if="loading || essloading || dowloadLoading">
      <spinner></spinner>
    </div>
    <div
      class="flex justify-center"
      v-else-if="errors || esserror || updateUserError || downloadError"
    >
      <p class="text-xl font-bold text-red-600">Request has failed!</p>
    </div>
    <div class="wrapper-medium" v-else>
      <div class="">
        <h1>Select format and download</h1>
        <h3>Datafile: {{ props.datafileTitle }}</h3>
        <select
          id="downloadFormat"
          class="input-group__list-item-select"
          :value="selectedFormat"
          v-model="selectedFormat"
        >
          <option disabled value="null">Select format</option>
          <option value="SAV">SAV</option>
          <option value="DTA">DTA</option>
          <option value="CSV">CSV</option>
        </select>

        <button
          type="button"
          @click="handleDownloadBtnClick"
          class="button button__small button__primary grid__item"
          :disabled="!selectedFormat"
        >
          START DOWNLOAD
        </button>

      </div>

      <div v-if="user.singleDownload">
        <h2>Previous downloads</h2>
        <div v-for="(download, index) in user.singleDownload.slice().reverse()" :key="index">
          <a :href="ESSserachUrl(download.studyUrn)" target="_blank">
           {{ download.datafileTitle }}
          </a>
          <span> ( </span>
          {{ download.format }}
          <span> ) </span>
          <span>- </span>
          {{ dateFormat(download.createdAt) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useQuery, useResult, useMutation } from "@vue/apollo-composable";
import { DOWNLOAD, ESS_USER, REGISTER_DOWNLOAD } from "@/graphql/documents";
import { unref, ref } from "vue";
import Spinner from "@/components/Spinner";
import { config, FORMAT } from "@/shared/config";
import moment from "moment";

export default {
  name: "Download",
  props: {
    studyUrn: { type: String, required: true },
    datafileUrn: { type: String, required: true },
    datafileTitle: { type: String, required: true },
    ssoId: { type: String, required: true },
  },
  components: { Spinner },
  setup(props) {
    const enabled = ref(false);
    const registered = ref(false);
    const dowloadLoading = ref(false);
    const downloadUrl = ref(null);
    const downloadError = ref(null);
    const selectedFormat = ref(null);
    const { result, essloading, esserror } = useQuery(ESS_USER, {
      ssoId: unref(props.ssoId),
    });
    const user = useResult(result, [], (data) => data.ESS.user);
    const datafileId = props.datafileUrn.split(":")[3];
    const version = props.datafileUrn.split(":")[4];
    const agencyID = props.datafileUrn.split(":")[2];
    const studyId = props.studyUrn.split(":")[3];
    const {
      mutate: registeDownload,
      error: updateUserError,
      onDone,
    } = useMutation(REGISTER_DOWNLOAD, () => ({
      variables: {
        datafileUrn: props.datafileUrn,
        datafileTitle: props.datafileTitle,
        studyUrn: props.studyUrn,
        format: selectedFormat.value,
        orderedBy: unref(user).id,
      },
    }));
    onDone(() => {
      registered.value=true
      openUrl()
    });
    const { onResult, loading, errors } = useQuery(
      DOWNLOAD,
      {
        datafileId: datafileId,
        version: Number(version),
        format: selectedFormat,
        agencyId: agencyID,
        instance: config.INSTANCE,
      },
      () => ({
        enabled: enabled.value,
      })
    );
    onResult((response) => {
      if (response.data.ESS) {
        downloadUrl.value = response.data.ESS.download;
        dowloadLoading.value = true;
        registeDownload();
      } else {
        downloadError.value = "Error";
      }
    });
    const openUrl= function (){
      if (downloadUrl.value && registered.value) {
        window.open(downloadUrl.value)
        window.location.href = config.ESS_SEARCH + "/en/study/" + props.studyUrn;
      }
    };
    const dateFormat = function (value) {
      if (value) {
        return moment(String(value)).format("LL");
      }
    };
    const ESSserachUrl = function (value) {
      return config.ESS_SEARCH + "/en/study/" + value.split(":")[3];
    };
    const handleDownloadBtnClick = () => {
      enabled.value = true;
    };
    return {
      dateFormat,
      props,
      ESSserachUrl,
      selectedFormat,
      user,
      enabled,
      esserror,
      essloading,
      dowloadLoading,
      downloadError,
      openUrl,
      loading,
      updateUserError,
      errors,
      handleDownloadBtnClick,
    };
  },
};
</script>

<style scoped>
.input-group__list-item-select {
  border: 2px solid var(--color-gray);
  border-radius: 6px;
  width: 40%;
  background-color: white;
  overflow: hidden;
  font-size: var(--font-size-small);
  padding: var(--space-x-small) var(--space-x-small);
  margin: var(--space-x-small);
  font-family: var(--font-family-serif);
}
</style>
