<template>
  <div class="wrapper-medium wrapper-space">
    <div v-if="loading">
      <spinner></spinner>
    </div>
    <div class="flex justify-center" v-else-if="error">
      <p class="text-xl font-bold text-red-600">Request has failed!</p>
    </div>
    <div v-else-if="user && needUpdate(user) && props.ssoId">
      <UpdateUser
        :ESSUser="user"
        :studyUrn="studyUrn"
        :datafileUrn="datafileUrn"
        :datafileTitle="datafileTitle"
      ></UpdateUser>
    </div>
    <div v-else-if="!user && props.ssoId">
      <NewUser
        :ssoUser="props.ssoId"
        :studyUrn="studyUrn"
        :datafileUrn="datafileUrn"
        :datafileTitle="datafileTitle"
      ></NewUser>
    </div>
  </div>
</template>

<script>
import { unref } from "vue";
import { useQuery, useResult } from "@vue/apollo-composable";
import { ESS_USER } from "../graphql/documents";
import Spinner from "@/components/Spinner";
import NewUser from "@/components/NewUser";
import UpdateUser from "@/components/UpdateUser";
import router from "@/router";

export default {
  name: "User",
  components: { Spinner, NewUser, UpdateUser },
  props: {
    studyUrn: { type: String, required: true },
    datafileUrn: { type: String, required: true },
    datafileTitle: { type: String, required: true },
    ssoId: { type: String, require: true },
  },

  setup(props) {
    const { result, loading, error } = useQuery(ESS_USER, {
      ssoId: unref(props.ssoId),
    });
    const user = useResult(result, [], (data) => data.ESS.user);
    const needUpdate = function (user) {
      if (user) {
        const lastModified = user.modifiedAt;
        const now = new Date();
        var lastModifiedTime = new Date(lastModified);
        const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
        const timeDiffInMs = now.getTime() - lastModifiedTime.getTime();
        if (timeDiffInMs >= thirtyDaysInMs) {
          return true;
        } else {
          return router.push({
            name: "Download",
            params: {
              ssoId: props.ssoId,
              studyUrn: props.studyUrn,
              datafileUrn: props.datafileUrn,
              datafileTitle: props.datafileTitle,
            },
          });
        }
      }
    };
    return {
      props,
      needUpdate,
      loading,
      error,
      user,
    };
  },
};
</script>

<style scoped>
.input-group {
  margin-top: var(--space-x-small);
  margin-bottom: var(--space-x-large);
  padding: 0;
  font-family: var(--font-family-sans);
}

.input-group__list-item {
  border: 1px solid var(--color-gray);
  width: 100%;
  overflow: hidden;
  font-size: var(--font-size-small);
  padding: var(--space-x-small) var(--space-x-small);
  margin-top: var(--space-x-small);
  font-family: var(--font-family-serif);
}

.input-group__list-item-select {
  border: 1px solid var(--color-gray);
  width: 70%;
  background-color: white;
  overflow: hidden;
  font-size: var(--font-size-small);
  padding: var(--space-x-small) var(--space-x-small);
  margin-top: var(--space-x-small);
  font-family: var(--font-family-serif);
}

.input-group__list-item-label {
  padding-right: var(--space-xxx-large);
  padding-bottom: var(--space-small);
}

.input-group__list-checkbox {
  padding: var(--space-small);
}

.input-group__list {
  padding: var(--space-x-small) var(--space-x-small);
}

.dot {
  height: 13px;
  width: 13px;
  background-color: var(--color-red-dark);
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
</style>
