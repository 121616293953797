<template>
  <div class="wrapper-narrow">
    <br />
    <h1>Error 404: File not found</h1>
    <p>The page you requested cannot be found.</p>
    <p>If you followed a link to this page please let us know - send an e-mail to <a href="mailto:essdatasupport@sikt.no">essdatasupport@sikt.no</a>. If possible, please include the address of the page that led you here.</p>
    <p>Thank you in advance!</p>
    <br />
    <br />
    <br />
    <br />
    <br />
  </div>
</template>

<script>
export default {}
</script>
