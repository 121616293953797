<template>
  <div>
    <h1>User Registration</h1>
    <h3 class="agreement__header" :class="{open: doShow}" @click="doShow = !doShow">ESS User agreement</h3>
    <div v-show="doShow">
    <ul>
      <li>We use your personal information to produce aggregated statistics on use of the ESS website including data download and use (online analysis). No individual can be identified in the aggregated statistics.</li>
      <li>From time to time, we may send you a request to participate in ESS user surveys.</li>
      <li>We may use a third party and will share your email address for the sole purpose of administering an ESS user survey. Your email address will not be stored by the third party beyond an agreed period and will not be transferred outside the EU/EEA.</li>
      <li>We will not use your contact details in any other way.</li>
      <li>Your contact details will be deleted when you delete your account, or after being inactive for 5 years.</li>
      <li>You can access, modify or delete your information when logged in. If you want to access your logs on data download and use, please contact <a href="mailto:essdata@nsd.no">essdata@nsd.no</a>. For more information, see our <a href="https://www.europeansocialsurvey.org/about/web_privacy.html">privacy declaration.</a></li>
    </ul>
    <p>Data Protection Officer: Mathilde Steinsvåg Hansen<br/>email: <a href="mailto:dpo.esseric@europeansocialsurvey.org">dpo.esseric@europeansocialsurvey.org</a></p>
    <p>Sikt - Norwegian Agency for Shared Services in Education and Research<br/>Harald Hårfagres gate 29<br/>NO-5007 Bergen<br/>T: (+47) 55 58 21 17<br/>Fax: +47-55 589650<br/>
    <a href="mailto:essdatasupport@sikt.no">essdatasupport@sikt.no</a>
    </p>
    <p>Data controller: ESS ERIC  <a href="mailto:ess@city.ac.uk">ess@city.ac.uk</a></p>
    </div>
    <br/>
    <h3>
      Please enter your information in the fields below.<br/>Required fields are marked with a red dot.
    </h3>
    <div class="input-group">
      <Input
        id="first-name"
        require="true"
        labelText="First name:"
        placeholder="First name"
        v-model="model.firstName.$model"
        :validate="model.firstName"
      />
      <Input
        id="surname"
        require="true"
        labelText="Surname:"
        placeholder="Surname"
        v-model="model.surname.$model"
        :validate="model.surname"
      />

      <Input
        id="email"
        require="true"
        labelText="Email address:"
        placeholder="Email address"
        v-model="model.email.$model"
        :validate="model.email"
      />

      <h4>
        If you are a private individual, please enter Private in the institution
        box.
      </h4>
      <Input
        id="institution"
        labelText="Institution:"
        require="true"
        placeholder="Institution"
        v-model="model.institution.$model"
        :validate="model.institution"
      />
      <Input
        id="department"
        placeholder="Department"
        labelText="Department:"
        v-model="user.department"
      />
      <Input
        id="country"
        require="true"
        labelText="Country:"
        placeholder="Country"
        v-model="model.country.$model"
        :validate="model.country"
      />
      <div class="input-group__list">
        <label class="input-group__list-item-label"
          ><span class="dot"></span>Type of activity:
        </label>
        <select
          id="typeOfActivity"
          class="input-group__list-item-select"
          :value="typeOfActivity"
          v-model="model.typeOfActivity.$model"
        >
          <option value="FACULTY_AND_RESEARCH">Faculty and research</option>
          <option value="PHD_THESIS">Ph.d. thesis</option>
          <option value="STUDENT">Student</option>
          <option value="GOVERNMENT">Government</option>
          <option value="ORGANISATION">Organization</option>
          <option value="JOURNALIST">Journalist</option>
          <option value="PRIVATE_ENTERPRISE">Private enterprise</option>
          <option value="PRIVATE_INDIVIDUAL">Private individual</option>
          <option value="OTHER">Other</option>
        </select>
        <span v-if="model.typeOfActivity.$invalid" class="text-danger">
          <slot>
            <ul class="list-unstyled">
              <li v-for="e in model.typeOfActivity.$errors" :key="e.$message">
                {{ e.$message }}
              </li>
            </ul>
          </slot>
        </span>
      </div>
      <div class="input-group__list">
        <label class="input-group__list-item-label"
          ><span class="dot"></span>Academic discipline:
        </label>
        <select
          id="academicDiscipline"
          class="input-group__list-item-select"
          :value="academicDiscipline"
          v-model="model.academicDiscipline.$model"
        >
          <option value="SOCIOLOGY">Sociology</option>
          <option value="POLITICAL_SCIENCE">Political science</option>
          <option value="ECONOMICS">Economics</option>
          <option value="STATISTICS_AND_METHODOLOGY">
            Statistic and methodology
          </option>
          <option value="PSYCHOLOGY_AND_SOCIAL_PSYCHOLOGY">
            Psychology and social psychology
          </option>
          <option value="PUBLIC_ADMINISTRATION_AND_POLICY">
            Public administration and policy
          </option>
          <option value="ARTS_AND_HUMANITIES">Arts and humanities</option>
          <option value="OTHER">Other</option>
          <option value="NOT_RELEVANT">Not relevant</option>
        </select>
        <span v-if="model.academicDiscipline.$invalid" class="text-danger">
          <slot>
            <ul class="list-unstyled">
              <li
                v-for="e in model.academicDiscipline.$errors"
                :key="e.$message"
              >
                {{ e.$message }}
              </li>
            </ul>
          </slot>
        </span>
      </div>
      <div>
        <input type="checkbox" id="checkbox" v-model="user.subscription" />
        <label class="input-group__list-checkbox"
          >Please click here if you would like to receive ESS News and User
          Bulletin by e-mail
        </label>
      </div>
      <br />
      <div>
        <button
          type="button"
          @click="handleRegisterBtnClick"
          class="button button__small button__primary"
          :disabled="model.$invalid"
        >
          Register
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, unref, computed } from "vue";
import { REGISTER_USER } from "@/graphql/documents";
import { useMutation } from "@vue/apollo-composable";
import Input from "@/components/Input";
import { required, email } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import router from "@/router";

export default {
  name: "NewUser",
  data() {
    return {
      doShow: true
    }
  },
  props: {
    format: { type: String, required: true },
    studyUrn: { type: String, required: true },
    datafileUrn: { type: String, required: true },
    datafileTitle: { type: String, required: true },
    ssoUser: { type: String, require: true },
  },
  components: { Input },
  setup(props) {
    const user = reactive({
      firstName: "",
      surname: "",
      email: "",
      ssoId: props.ssoUser,
      institution: "",
      department: "",
      country: "",
      subscription: false,
      typeOfActivity: "",
      academicDiscipline: "",
    });
    const rules = computed(() => {
      return {
        firstName: { required },
        surname: { required },
        institution: { required },
        country: { required },
        typeOfActivity: { required },
        academicDiscipline: { required },
        email: { required, email },
      };
    });
    const { mutate: register, onDone } = useMutation(REGISTER_USER, {
      variables: unref(user),
    });
    onDone(() => {
      router.push({
        name: "Download",
        params: {
          ssoId: props.ssoUser,
          format: props.format,
          studyUrn: props.studyUrn,
          datafileUrn: props.datafileUrn,
          datafileTitle: props.datafileTitle,
        },
      });
    });
    const model = useValidate(rules, user);

    const handleRegisterBtnClick = () => {
      //model.$validate(); // checks all inputs
      if (!model.$error) {
        register();
      } else {
        alert("Form failed validation");
      }
    };

    return {
      user,
      model,
      handleRegisterBtnClick,
    };
  },
};
</script>

<style scoped>
@import '../assets/css/userAgreement.css';

.input-group {
  margin-top: var(--space-x-small);
  margin-bottom: var(--space-x-large);
  padding: 0;
  font-family: var(--font-family-sans);
}

.input-group__list-item {
  border: 1px solid var(--color-gray);
  width: 100%;
  overflow: hidden;
  font-size: var(--font-size-small);
  padding: var(--space-x-small) var(--space-x-small);
  margin-top: var(--space-x-small);
  font-family: var(--font-family-serif);
}

.input-group__list-item-select {
  border: 1px solid var(--color-gray);
  width: 70%;
  background-color: white;
  overflow: hidden;
  font-size: var(--font-size-small);
  padding: var(--space-x-small) var(--space-x-small);
  margin-top: var(--space-x-small);
  font-family: var(--font-family-serif);
}

.input-group__list-item-label {
  padding-right: var(--space-xxx-large);
  padding-bottom: var(--space-small);
}

.input-group__list-checkbox {
  padding: var(--space-small);
}

.input-group__list {
  padding: var(--space-x-small) var(--space-x-small);
}
</style>
