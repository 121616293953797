<template>
  <div class="input-group__list">
    <label for="id" class="input-group__list-item-label">
      <span v-if="require" class="dot"></span> {{ labelText }}
    </label>
    <input
      id="id"
      type="text"
      class="input-group__list-item"
      :placeholder="placeholder"
      :value="modelValue"
      @change="$emit('update:modelValue', $event.target.value)"
    />
    <error-span v-if="validate" :property="validate" />
  </div>
</template>

<script>
import ErrorSpan from "@/components/ErrorSpan";

export default {
  name: "Input",
  components: { ErrorSpan },
  props: {
    id: String,
    labelText: String,
    modelValue: String,
    require: String,
    validate: Object,
    placeholder: String,
  },
};
</script>
<style scoped>

.input-group__list-item {
  border: 1px solid var(--color-gray);
  width: 100%;
  overflow: hidden;
  font-size: var(--font-size-small);
  padding: var(--space-x-small) var(--space-x-small);
  margin-top: var(--space-x-small);
  font-family: var(--font-family-serif);
}


.input-group__list-item-label {
  padding-right: var(--space-xxx-large);
  padding-bottom: var(--space-small);
}

.input-group__list {
  padding: var(--space-x-small) var(--space-x-small);
}

.dot {
  height: 13px;
  width: 13px;
  background-color: var(--color-red-dark);
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}
</style>
