<template>
  <div  class="hero hero__dark">
    <nav class="wrapper-large wrapper-space menu">
      <div class="menu__logo">
        <router-link to="/ess-search">
          <img src="../assets/ess-logo-top.png" />
        </router-link>
      </div>
      <div v-if="$route.params.ssoId" class="menu__navigation">
        <router-link
          class="menu__mypage"
          :to="{
            name: 'Update',
          }"
        >
          My profile
        </router-link>
      </div>
    </nav>
  </div>
</template>
<style scoped>
@import "../assets/css/hero.css";
</style>
