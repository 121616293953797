import { gql } from "@apollo/client/core";
export const USER_SSO_ID = gql`
  query Me {
    me {
      id
    }
  }
`;

export const ESS_USER = gql`
  query ESS_USER($ssoId: ID!) {
    ESS {
      user(ssoId: $ssoId) {
        id
        ssoId
        firstName
        surname
        email
        institution
        department
        country
        subscription
        typeOfActivity
        academicDiscipline
        modifiedAt  
        singleDownload {
          id
          createdAt
          datafileUrn
          studyUrn
          datafileTitle
          format
        }
      }
    }
  }
`;
export const DOWNLOAD = gql`
  query download(
    $datafileId: ID!
    $version: Int!
    $agencyId: String!
    $format: DownloadFormat!
    $instance: String!
  ) {
    ESS {
      download(
        input: {
          datafileId: $datafileId
          version: $version
          agencyId: $agencyId
          format: $format
          instance: $instance
        }
      )
    }
  }
`;
export const ESS_USERS = gql`
  query ESS_USER {
    ESS {
      users {
        id
        ssoId
        firstName
        surname
        email
        institution
        department
        country
        modifiedAt
        subscription
        typeOfActivity
        academicDiscipline
      }
    }
  }
`;
export const REGISTER_USER = gql`
  mutation registerNewUser(
    $ssoId: ID!
    $firstName: String!
    $surname: String!
    $email: String!
    $institution: String!
    $department: String
    $country: String!
    $subscription: Boolean!
    $typeOfActivity: TypeOfActivity!
    $academicDiscipline: AcademicDiscipline!
  ) {
    ESS {
      registerNewUser(
        input: {
          ssoId: $ssoId
          firstName: $firstName
          surname: $surname
          email: $email
          institution: $institution
          department: $department
          country: $country
          subscription: $subscription
          typeOfActivity: $typeOfActivity
          academicDiscipline: $academicDiscipline
        }
      ) {
        id
        ssoId
        firstName
        surname
        email
        institution
        department
        country
        subscription
        typeOfActivity
        academicDiscipline
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUserInfo(
    $id: ID!
    $ssoId: ID!
    $firstName: String!
    $surname: String!
    $email: String!
    $institution: String!
    $department: String
    $country: String!
    $subscription: Boolean!
    $typeOfActivity: TypeOfActivity!
    $academicDiscipline: AcademicDiscipline!
  ) {
    ESS {
      updateUserInfo(
        input: {
          id: $id
          ssoId: $ssoId
          firstName: $firstName
          surname: $surname
          email: $email
          institution: $institution
          department: $department
          country: $country
          subscription: $subscription
          typeOfActivity: $typeOfActivity
          academicDiscipline: $academicDiscipline
        }
      ) {
        id
        ssoId
        firstName
        surname
        email
        institution
        department
        country
        subscription
        typeOfActivity
        academicDiscipline
      }
    }
  }
`;

export const REGISTER_DOWNLOAD = gql`
  mutation registerSingleDownload(
    $datafileUrn: String!
    $datafileTitle: String!
    $studyUrn: String!
    $format: DownloadFormat!
    $orderedBy: ID!
  ) {
    ESS {
      registerSingleDownload(
        input: {
          datafileUrn: $datafileUrn
          datafileTitle: $datafileTitle
          studyUrn: $studyUrn
          format: $format
          orderedBy: $orderedBy
        }
      ) {
        id
        createdAt
        datafileUrn
        datafileTitle
        studyUrn
        format
      }
    }
  }
`;
