<template>
  <section class="container">
    <Hero></Hero>
    <div class="blockContent">
      <router-view :key="this.$route.path" />
    </div>
  </section>
</template>

<script>
import Hero from "./components/Hero";

export default {
  components: {
    Hero,
  },
};
</script>

<style lang="scss">
@import "./assets/css/nsd-style.css";
@import "./assets/css/media.css";
@import "./assets/css/elements.css";
@import "./assets/css/typography.css";
@import "./assets/css/wrapper.css";
@import "./assets/css/table.css";
@import "./assets/css/menu.css";
@import "./assets/css/button.css";

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
