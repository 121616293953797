export interface AppConfig {
  AUTH_SERVER: string;
  INSTANCE: string;
  ESS_SEARCH: string;
  API_URL: string;
}

export const config: AppConfig = (() => {
  if (process.env.NODE_ENV !== "production") {
    return {
      AUTH_SERVER: "https://sso-stage.nsd.no",
      INSTANCE: "processing",
      ESS_SEARCH: "https://ess-search-stage.nsd.no",
      API_URL: "https://api-stage.nsd.no/graphql",
      //API_URL: "http://localhost:8084/graphql",
    };
  }
  return (window as any)._env;
})();
