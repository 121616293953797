import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import Users from "../views/Users.vue";
import About from "../views/About.vue";
import Download from "../views/Download.vue";
import NotFound from "../components/404.vue";
import { config } from "@/shared/config";
import Update from "../views/Update.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "About",
    component: About,
  },
  {
    path: "/ess-search",
    component: About,
    beforeEnter: (to) => {
      location.href = config.ESS_SEARCH;
    },
  },
  {
    path: "/user/:ssoId/title/:datafileTitle/datafile/:datafileUrn/study/:studyUrn",
    name: "Home",
    component: Home,
    props: true,
  },
  {
    path: "/update/:ssoId/title/:datafileTitle/datafile/:datafileUrn/study/:studyUrn",
    name: "Update",
    component: Update,
    props: true,
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
  },
  {
    path: "/download/:datafileTitle/datafile/:datafileUrn/study/:studyUrn/ssoId/:ssoId",
    name: "Download",
    component: Download,
    props: true,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
  },
];
const router = createRouter({
  //history: createWebHashHistory(),
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
});
export default router;
