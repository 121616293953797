<template>
  <div className="flex justify-center" v-if="loading">
    <p className="text-xl font-bold text-gray-500">Request is loading...</p>
  </div>
  <div className="flex justify-center" v-else-if="error">
    <p className="text-xl font-bold text-red-600">Request has failed!</p>
  </div>
  <div v-else-if="ssoId" className="grid__item">
    <User
      :ssoId="ssoId"
      :studyUrn="studyUrn"
      :datafileUrn="datafileUrn"
      :datafileTitle="datafileTitle"
    />
  </div>
  <div v-else className="flex justify-center">
    <p className="text-xl font-bold text-red-600">Logg in has failed!</p>
  </div>
</template>

<script>
import User from "../components/User.vue";

export default {
  props: {
    ssoId: { type: String, required: true },
    studyUrn: { type: String, required: true },
    datafileUrn: { type: String, required: true },
    datafileTitle: { type: String, required: true },
  },
  name: "Home",
  components: {
    User,
  },
};
</script>

<style scoped></style>
