<template>
<span v-if="property.$invalid" class="text-danger">
  <slot>
    <ul class="list-unstyled">
      <li v-for="e in property.$errors" :key="e.$message">
{{e.$message}}
      </li>
    </ul>
  </slot>
</span>
</template>

<script>
export default {
  name: "ErrorSpan",
  props: {
    property: Object,

  },
}
</script>

<style scoped>

</style>