<template>
  <div className="flex justify-center" v-if="loading">
    <p className="text-xl font-bold text-gray-500">Request is loading...</p>
  </div>
  <div className="flex justify-center" v-else-if="error">
    <p className="text-xl font-bold text-red-600">Request has failed!</p>
  </div>
  <div className="wrapper-medium ">
    <h1>ESS users</h1>
    <div class="data-table">
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Institution</th>
            <th>country</th>
            <th>typeOfActivity</th>
            <th>academicDiscipline</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, key) in users" :key="key">
            <td>{{ user.firstName }} {{ user.surname }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.institution }}</td>
            <td>{{ user.country }}</td>
            <td>{{ user.typeOfActivity }}</td>
            <td>{{ user.academicDiscipline }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { useQuery, useResult } from "@vue/apollo-composable";
import { ESS_USERS } from "../graphql/documents";

export default {
  name: "Users",
  setup() {
    const { result, loading, error } = useQuery(ESS_USERS);
    const users = useResult(result, [], (data) => data.ESS.users);
    return {
      loading,
      error,
      users,
    };
  },
};
</script>

<style scoped></style>
